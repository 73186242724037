<template>
    <label
        :id="`label-${props.for}`"
        :for="props.for"
        class="block gap-2 self-start text-left text-sm font-medium text-gray-700"
    >
        <slot />

        <span v-if="required" class="ml-1 font-extrabold text-red-600">
            {{ requiredSymbol }}
        </span>
    </label>
</template>

<script setup lang="ts">
const props = defineProps({
    for: {
        type: String,
        default: '',
    },
    required: {
        type: Boolean,
        default: false,
    },
    requiredSymbol: {
        type: String,
        default: '*',
    },
})
</script>
