<template>
    <div :id="ELEMENT_ID" class="inline-block">
        <div
            :id="`trigger-${ELEMENT_ID}`"
            ref="triggerRef"
            :aria-describedby="`tooltip-content-${ELEMENT_ID}`"
            class="relative"
            @mouseenter="onMouseEnter"
            @mouseleave="onMouseLeave"
            @focus.capture="onFocus"
            @blur.capture="onBlur"
            @keydown.escape.stop="onEscape"
        >
            <!-- Tooltip trigger -->
            <slot />

            <!-- Tooltip -->
            <div
                v-bind="$attrs"
                :id="`tooltip-content-${ELEMENT_ID}`"
                ref="popperRef"
                class="absolute z-[1]"
                role="tooltip"
                :aria-hidden="!showTooltip"
            >
                <Transition name="fade" @before-enter="updatePopper()">
                    <div
                        v-if="showTooltip"
                        class="my-1 h-full w-full rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm shadow-md"
                    >
                        <p v-if="content">{{ content }}</p>

                        <slot v-else name="content" />
                    </div>
                </Transition>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Options, Placement } from '@popperjs/core'

const ELEMENT_ID = `tooltip-${useId()}`

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    content: {
        type: String,
        default: '',
    },
    placement: {
        type: String as PropType<Placement>,
        default: 'top' as Placement,
    },
})

defineOptions({
    inheritAttrs: false,
})

const slots = useSlots()
const { createPopper, destroyPopper, updatePopper } = popper()

const triggerRef = ref<HTMLElement | null>(null)
const popperRef = ref<HTMLElement | null>(null)
const isVisible = ref(false)

const showTooltip = computed(() => isVisible.value && !props.disabled)

onMounted(() => {
    const triggerEl = triggerRef.value as HTMLElement
    const popperEl = popperRef.value as HTMLElement

    const options: Partial<Options> = {
        placement: props.placement,
    }

    createPopper(triggerEl, popperEl, options)

    validateTooltip()
})

onUnmounted(() => destroyPopper())

function onMouseEnter() {
    isVisible.value = true
}

function onMouseLeave() {
    isVisible.value = false
}

function onFocus() {
    isVisible.value = true
}

function onBlur() {
    isVisible.value = false
}

function onEscape() {
    isVisible.value = false
}

function validateTooltip() {
    if (!props.content && !slots.content) {
        console.error(
            `[${ELEMENT_ID}] Neither content prop nor content slot is provided`,
        )
    }
}
</script>
